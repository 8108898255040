import L from 'leaflet'
import omnivore from 'leaflet-omnivore'
import JSZip from 'jszip'
import shp from 'shpjs'

export const createCustomControl = ({
  title,
  imageUrl,
  onClick,
  dataSet = {},
  id,
  options
}) => {
  const customControl = L.Control.extend({
    options: {
      position: 'bottomright',
      ...options
    },

    onAdd: () => {
      const container = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom')

      L.DomEvent.disableClickPropagation(container)

      const style = {
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'white',
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: '20px 20px',
        backgroundPosition: 'center',
        width: '34px',
        height: '34px',
        cursor: 'pointer'
      }

      container.title = title
      if (id) { container.id = id }

      if (dataSet) {
        Object.keys(dataSet).forEach(key =>
          container.dataset[key] = dataSet[key]
        )
      }

      Object.keys(style).forEach(key => {
        container.style[key] = style[key]
      })

      container.onclick = onClick

      return container
    }
  })

  return new customControl
}

export const changeCustomControl = (imageUrl, backgroundColor, id) => {
  const element = L.DomUtil.get(id)

  const newStyle = {
    backgroundImage: `url(${imageUrl})`,
    backgroundColor: backgroundColor
  }

  Object.keys(newStyle).forEach(key => {
    element.style[key] = newStyle[key]
  })
}

export const parseFilesToAreas = async (files) => {
  let newAreas = []

  for (const file of files) {
    const parseResult = await parseFileToArea(file)
    newAreas = [...newAreas, ...parseResult]
  }

  return newAreas
}

export const parseFileToArea = async (file) => {
  const extension = file.name.split(".").pop().toLowerCase();
  const fileParse = findParser(extension);

  if (!fileParse) throw new Error(I18n.t('errors.invalid_file_format'))

  return await fileParse.parse(file)
}

export const filesMimeTypes = ['.zip','.kml','.kmz',]

export const acceptedFormats = [
  '.kml',
  '.kmz',
  `.shp (${I18n.t('v4/area.new.shp_format')})`
]

export const locationPickerPopupContent = (lat, lng, classes) => {
  const places = 6
  const resumedLat = resumeCoordinate(lat, places)
  const resumedLng = resumeCoordinate(lng, places)
  let buttonText = I18n.t('leaflet.location_picker.copy')

  return `
    <div class='${classes.Picker_wrapper}'>
      <p class='${classes.Picker_unit}'>${I18n.t('units.latitude')}:</p>
      <p class='${classes.Picker_coordinates}'> ${resumedLat}</p>
    </div>
    <div class='${classes.Picker_wrapper}'>
      <p class='${classes.Picker_unit}'>${I18n.t('units.longitude')}:
      <p class='${classes.Picker_coordinates}'>${resumedLng}</p>
    </div>

    <div>
      <button
        onClick="(function() {
          navigator.clipboard.writeText('${lat}, ${lng}')
          let clipboardButton = document.getElementById('clipboard-button')
          clipboardButton.childNodes[0].nodeValue = '${I18n.t('leaflet.location_picker.copied')}'
        })()"
        id='clipboard-button'
        class='${classes.Picker_button}'
        data-intercom-target='location-picker-copy'
      >
        ${buttonText}
      </button>
    </div>
  `
}

const resumeCoordinate = (coordinate, places) => {
  let resumedCoordinate = coordinate.toString()
  resumedCoordinate = resumedCoordinate.slice(0, (resumedCoordinate.indexOf(".")) + places)

  return resumedCoordinate
}

const fileParsers = [
  {
    extension: 'kml',
    parse: async (file) => await parseKML(file)
  },
  {
    extension: 'kmz',
    parse: async (file) => await parseKMZ(file)
  },
  {
    extension: 'zip',
    parse: async (file) => await parseSHP(file)
  }
]

const parseKML = async (file) => {
  try {
    const fileText = await file.text()

    const kml = omnivore.kml.parse(fileText)

    const areas = Object.keys(kml._layers).map(key => kml._layers[key])

    return parseAreas(areas)
  }
  catch (error) {
    console.error(error)
  }
}

const parseKMZ = (file) =>
  fetch(URL.createObjectURL(file))
    .then((response) => response.blob())
    .then(JSZip.loadAsync)
    .then((zip) => zip.file('doc.kml').async('string'))
    .then((fileText) => {
      const kml = omnivore.kml.parse(fileText)

      const areas = Object.keys(kml._layers).map(key => kml._layers[key])

      return parseAreas(areas)
    })
    .catch(error => console.error(error))

const parseSHP = async (file) =>
  file.arrayBuffer()
    .then(shp)
    .then(L.geoJSON)
    .then((shp) => {
      const areas = Object.keys(shp._layers).map(key => shp._layers[key])

      return parseAreas(areas)
    })
    .catch(error => console.error(error))

const parseAreas = (areas) =>
  areas.map((area, index) => {
    const coordinates = getAreaCoordinates(area).map(({ lat, lng }) => ({
      lat, lng
    }))

    const areaPol = L.GeometryUtil.geodesicArea(coordinates)

    const center = getAreaCenter(area)
    const size_ha = getAreaSize(areaPol)
    const name = `${area.feature.properties.name}-${index}`
    const kind = 'polygon'

    return {
      _leaflet_id: area._leaflet_id,
      name,
      kind,
      center,
      latitude: center.lat,
      longitude: center.lng,
      size_ha,
      coordinates
    }
  })

const getAreaCoordinates = (area) => area.getLatLngs()[0]

const getAreaCenter = (area) => area.getBounds().getCenter()

const getAreaSize = (areaPol) => L.GeometryUtil.readableArea(areaPol, true)

const findParser = (extension) => fileParsers.find(parser => parser.extension === extension)
